﻿/* globals BuscadorDTO, __tcfapi*/

var jquery = require("jquery");
var UIKeyCodes = require("../../Global/keycodes");
var HabUtils = require("../../Global/habitaclia-utils");
var HabEvents = require("../../Global/habitaclia-events");

(function () {
    "use strict";
    var guionesMinimosEnUrl = 1;

    var __buscadorDTO;
    function Buscador(buscadorDTO) {
        __registerEvents.call(this);
        __buscadorDTO = buscadorDTO;
        this.__resultadoPrevio = {};
    }

    var __cache = {};

    

    var __registerEvents = function () {
        var self = this;
        var timer = null;
        jquery('body').removeClass('wmLockScrollMainWindow');
        jquery("#input-search").on('keyup', function (e) {
            if ((e.which <= UIKeyCodes.UIKeyCodesEnum.ZETA_MAYUS && e.which >= UIKeyCodes.UIKeyCodesEnum.ZERO) ||
                    (e.which = UIKeyCodes.UIKeyCodesEnum.BACKSPACE)) {
                var texto = this.value;
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function () {
                    if (texto.length >= 3) {
                        __callbackTimerSuggest.call(self, texto);
                    }
                }, 300);
            }
            if (jquery("#idGeoLocalizacion").is(":checked")) {
                jquery("#idGeoLocalizacion").prop("checked", false);
            }
        });

        jquery("#input-search").on('click touchstart', function () {
            jquery("#input-search").focus();
            __desplazarHastaBuscador.call(self);
            __mostrarCruzInput();
            __guardarResultadoPrevio.call(self);
            this.value = "";
            __limpiarResultadosBuscador();
            __mostrarOpcionesPorDefecto();
        });

        jquery("body").on('click touchstart', function (e) {
            e = e || window.event;
            var target = e.target || e.srcElement;

            var $elementTarget = jquery(target);

            if (!$elementTarget.hasClass('lnk-result-search')
                && !$elementTarget.hasClass('input-search')
                && !$elementTarget.hasClass('boton')
                && !$elementTarget.hasClass('busquedaRecienteTitle')
                && !$elementTarget.hasClass('busquedaReciente')
                && !$elementTarget.hasClass('selecciona-provincia')
                && $elementTarget.attr('id') !== "labelIdGeoLocalizacion"
                && $elementTarget.attr('id') !== "lnkGeolocalizacion" && !($elementTarget.hasClass('selecciona-ubicacion') && e.type === "click")) {

                __limpiarResultadosBuscadorUI.call(self);
                __restaurarResultadoPrevio.call(self);
                __mostrarIconoGeolocalziacionInput.call(self);
            }

        });

        jquery("#idGeoLocalizacion").on('click', function () {
            __cargarBuscadorItemFromGeoLocation();
            __limpiarResultadosBuscadorUI.call(self);
           
        });

        jquery("#cruzCerrarBuscador").on('click', function () {
            __mostrarIconoGeolocalziacionInput();
            __limpiarResultadosBuscador();
            __restaurarResultadoPrevio.call(self);
        });

        jquery("#TipoOperacion").on('change', function () {
            __callbackChangeTipoOperacion();
            __replaceUrlNavigation.call(self);

        });

        jquery("#TipInmSeleccionado").on('change', function () {
            __replaceUrlNavigation.call(self);
            __mostrarOpcionesPorDefecto();
        });

        jquery("#buscadorSubmit").on('click', function () {
            var searchValue,
                urlNavigation;

            searchValue = jquery("#input-search").val();

            if (!searchValue) {
                return false;
            }

            urlNavigation = this.getAttribute('data-url');
            var numGuionesUrl = __getNumGuionesUrl(urlNavigation);
            if (urlNavigation && numGuionesUrl > guionesMinimosEnUrl)
            {
                document.location.href = urlNavigation;
                return false;
            }

            return true;
        });

        jquery(window).scroll(function () {
            var scrollTop = jquery(window).scrollTop();
            var detailPortal = document.querySelector('.detail-portal-wrapper.show');
            var buscadorHeight = !!jquery('#modal-buscador') ? jquery('#modal-buscador').height() : 500;
            buscadorHeight -= 120;
            if (scrollTop > buscadorHeight && !detailPortal) {
                jquery.publish(HabEvents.EventsEnum.CloseWindowModal);
            }
        });

        window.addEventListener('newZonesSelected', () => {
            let params = '';
            const { selectedZones, halfSelectedZones, defaultNavigationUrl } = window.FilterMapDTO;
            if (selectedZones.length > 0) {
                const town = selectedZones.filter(town => { return town.type === 'town'; });
                if (town.length === 0) {
                    params = '?';
                    const zonas = selectedZones.filter(zona => { return zona.type === 'zone'; }).map(zona => zona.id).join(',');
                    const distritosFull = selectedZones.filter(distrito => { return distrito.type === 'district'; }).map(zona => zona.id);
                    const distritosSemi = halfSelectedZones.filter(distrito => { return distrito.type === 'district'; }).map(zona => zona.id);
                    const distritos = [...distritosFull, ...distritosSemi];
                    if (zonas !== "") { params += `codzonas=${zonas}&`; }
                    if (distritos.length > 0) {
                        params += `coddists=${distritos.join(',')}`;
                    }
                }
            }
            const listUrl = defaultNavigationUrl + params;
            document.location.href = listUrl;
        });
        window.addEventListener('mapNavigatorCanceled', () => {
            const inputSearch = document.getElementById('input-search');
            inputSearch.click();
        });
    };

    var __getNumGuionesUrl = function 
        
        (urlNavigation) {
        return urlNavigation.split("-").length - 1;
    };

    var __desplazarHastaBuscador = function () {
        var $vmContent,
            offsetTopInputSearch,
            topWmContent,
            heightHeader,
            newTopWmContent,
            newTopBuscadorHome;
        
        $vmContent = jquery("#wmContent");
        offsetTopInputSearch = jquery("#input-search").offset().top;
        heightHeader = jquery("#header").height();

        /*Buscador Modal*/
        if ($vmContent.length !== 0) {
        
            topWmContent = $vmContent.offset().top;           
            newTopWmContent = offsetTopInputSearch - heightHeader - topWmContent;

            jquery('body').animate({
                scrollTop: newTopWmContent + 'px'
            }, 1000);
        }
        else {
            newTopBuscadorHome = offsetTopInputSearch - heightHeader;
            /*Buscador Home*/
            jquery("body").animate({
                scrollTop: newTopBuscadorHome
            }, 1000);
            if(__buscadorDTO.IsMobileApp === 'True')
            {
                jquery("#header").hide();
            }

        }
    };

    var __mostrarCruzInput = function () {
        jquery("#cruzCerrarBuscador").show();
        jquery("#labelIdGeoLocalizacion").hide();
    };

    var __mostrarIconoGeolocalziacionInput = function () {
        jquery("#cruzCerrarBuscador").hide();
        jquery("#labelIdGeoLocalizacion").show();
    };
    
    var __restaurarResultadoPrevio = function () {

        if (jquery("#input-search").val()==="" && this.__resultadoPrevio && this.__resultadoPrevio.texto) {
            jquery("#buscadorSubmit").attr("data-url", this.__resultadoPrevio.url);
            jquery("#input-search").val(this.__resultadoPrevio.texto);
        }
    };

    var __guardarResultadoPrevio = function () {
        if (jquery("#input-search").val() !== "") {
            this.__resultadoPrevio = {
                url: jquery("#buscadorSubmit").attr("data-url"),
                texto: jquery("#input-search").val()
            };
        }
    };

    var __replaceUrlNavigation = function () {
        var tipOpSelected,
            tipInmSelected,
            urlNavigation,
            urlNavigationSplitted,
            result;
        
        tipOpSelected = jquery("#TipoOperacion option:selected").attr("data-descbuscador");
        tipInmSelected = jquery("#TipInmSeleccionado option:selected").val();

        urlNavigation = jquery("#buscadorSubmit").attr("data-url");

        var textoParametrosExtraPermitidos = __getTextoParametrosExtraPermitidosBuscador(urlNavigation);
        urlNavigationSplitted = urlNavigation.split("-");

        result = "/" + tipOpSelected + "-" + tipInmSelected + "-";
        for (var i = 2; i < urlNavigationSplitted.length; i++) {
            var item = urlNavigationSplitted[i];
            var index = item.indexOf("?");

            if (index > -1) {
                item = item.substring(0, index);
            }

            result = result + __limpiarFiltroDeElementoUrl.call(this,item) + "-";
        }

        result = result.substring(0, result.length - 1);
        result = result + textoParametrosExtraPermitidos;
        //Caso especial obra nueva con distritos
        if (tipOpSelected === "obra_nueva" && result.indexOf("buscardistrito") > -1)
        {
            result = result.replace("buscardistrito", "listainmuebles");
        }
        result = __limpiarTipoProductoDuplicado(result);
        jquery("#buscadorSubmit").attr("data-url", result);
    };

    var __limpiarFiltroDeElementoUrl = function (elementoUrl) {

        for (var i = 0, length = __buscadorDTO.TiposFiltros.length; i < length; i++) {
            var filtro = __buscadorDTO.TiposFiltros[i].desfiltrobuscador;

            if (elementoUrl.indexOf(filtro + "_") > -1) {
                elementoUrl = elementoUrl.replace(filtro + "_", "");
            }
        }

        return elementoUrl;
    };

    var __limpiarTipoProductoDuplicado = function (urlBuscador) {
        var encontrado = false;
        var index = 0;
        while (encontrado === false && index < __buscadorDTO.ListaStringsTiposProductos.length) {
            var tipoProducto = "/" + __buscadorDTO.ListaStringsTiposProductos[index];
            if (urlBuscador.indexOf(tipoProducto) > -1) {
                urlBuscador = urlBuscador.replace(tipoProducto, "");
                encontrado = true;
            }
            index++;
        }
        return urlBuscador;
    };

    var __callbackChangeTipoOperacion = function () {
        var tipOpSeleccionado = jquery("#TipoOperacion option:selected").val();
        var tipoOperacion = __buscadorDTO.TiposOperacion[tipOpSeleccionado];
        var $selectTipInms = jquery("#TipInmSeleccionado");
           
        if(tipoOperacion){
            $selectTipInms.empty();

            for (var i = 0, length = tipoOperacion.tipInms.length; i < length ; i++) {
                $selectTipInms.append("<option value='" + tipoOperacion.tipInms[i].descTipBuscador + "' data-inmueble='" + tipoOperacion.tipInms[i].tipInm + "'>" + tipoOperacion.tipInms[i].descTip + "</option");
            }
        }
        __mostrarOpcionesPorDefecto();
    };

    var __callbackTimerSuggest = function (texto) {

        var self = this;

        var tipOpSelected = jquery("#TipoOperacion option:selected").val();
        var tipInmSelected = jquery("#TipInmSeleccionado option:selected").val();
        var keyBusqueda = tipOpSelected + "#" + tipInmSelected + "#" + texto;

        if (__cache[keyBusqueda] !== undefined) {
            __callbackSuggest.call(self, texto, __cache[keyBusqueda]);
        }
        else {
           
            
            var data = {
                tipoOperacion: tipOpSelected,
                tipoProducto: {
                    Des_Tip: tipInmSelected
                },
                textFromBuscador: texto                
            };

            __ShowGeoLocLoading();

            jquery.ajax({
                type: "POST",
                url: __buscadorDTO.UrlFind,
                data: data,
                cache: true,
                dataType: "json"
            }).done(function (data) {
                __callbackSuggest.call(self, texto, data);
                __cache[keyBusqueda] = data;
                __HideGeoLocLoading();
            }).fail(function (request, type) {
                console.log(type);
                __HideGeoLocLoading();
            });
        }
    };

    var __callbackSuggest = function (texto, data) {
        var buscadorItemList = [],
            buscadorItemListLength,
            buscadorItem,
            $searchResultContainer,
            $searchResult,
            textLiWithBoldPrefix,
            resultItem;

        buscadorItemList = data;
        buscadorItemListLength = data.length;

        __limpiarResultadosBuscador();

        

        if (buscadorItemListLength > 0) {
            $searchResultContainer = jquery("#search-result-container");

            $searchResultContainer.append("<ul id='search-result'>");

            $searchResult = jquery("#search-result");

            for (var i = 0; i < buscadorItemListLength; i++) {
                buscadorItem = buscadorItemList[i];

                var { CodPais, CodProv, CodCom, CodArea, CodPob, CodDist, TieneMapa, NomPob } = buscadorItem.GeoEntity;

                var formatPriority = __formatNumber.call(this, buscadorItem.Priority, 0, 3, '.', ',');

                textLiWithBoldPrefix = buscadorItem.TextShow.replace(texto, "<strong>" + texto + "</strong>");

                resultItem = document.createElement('li');
                resultItem.classList = 'buscadorItem';
                resultItem.dataset.value = i;
                resultItem.dataset.textSearch = buscadorItem.TextSearch;
                resultItem.dataset.textShow = buscadorItem.TextShow;
                resultItem.dataset.url = buscadorItem.UrlNavigation;

                if (TieneMapa && CodPob > 0) {
                    var defaultNavigationUrl = buscadorItem.UrlNavigation;
                    var mapData = `codPais=${CodPais}&codProv=${CodProv}&codCom=${CodCom}&codArea=${CodArea}&codPob=${CodPob}`;
                    var title = NomPob;
                    var selectedZones = [{ id: CodPob, type: "town", name: buscadorItem.TextSearch }];
                    if (CodDist > 0) {
                        selectedZones = [{ id: CodDist, type: "district", name: buscadorItem.TextSearch }];
                    }

                    var mapButton = document.createElement('button');
                    mapButton.type = 'button';
                    mapButton.classList = 'buscador-map-btn';
                    mapButton.innerHTML = textLiWithBoldPrefix + "<span>" + formatPriority + "</span>";

                    resultItem.appendChild(mapButton);

                    __bindMapButton(mapButton, mapData, selectedZones, title, defaultNavigationUrl);
                } else {
                    var anchor = document.createElement('a');
                    anchor.classList = 'lnk-result-search';
                    anchor.title = texto;
                    anchor.innerHTML = textLiWithBoldPrefix + "<span>" + formatPriority + "</span>";
                    anchor.href = buscadorItem.UrlNavigation;
                    
                    resultItem.appendChild(anchor);
                }
                $searchResult.append(resultItem);
            }

            $searchResult.on("click", ".lnk-result-search", function () {
                __limpiarResultadosBuscador();
                __callbackClickSelectElement.call(this);
            });
        }
    };

    var __bindMapButton = function (mapButton, mapData, selectedZones, title, defaultNavigationUrl) {
        const openCloseEvent = new window.Event('openCloseFilterModal');
        const firstTimeOpenFilterModal = new window.Event('firstTimeOpenFilterModal');
        
        function handleMapBtnClick() {
            window.FilterMapDTO = {
                mapData, selectedZones, title, halfSelectedZones:[], defaultNavigationUrl };
            window.dispatchEvent(openCloseEvent);
            window.dispatchEvent(firstTimeOpenFilterModal);
        }

        mapButton.addEventListener('touchstart', handleMapBtnClick);
        mapButton.addEventListener('click', handleMapBtnClick);
    };

    var __callbackClickSelectElement = function () {
        var idBuscadorItemSelected,
                    inputSearch,
                    textBuscadorItemSelected,
                    urlBuscadorItemSelected;

        idBuscadorItemSelected = this.getAttribute('data-value');
        textBuscadorItemSelected = this.getAttribute('data-text-show');
        urlBuscadorItemSelected = this.getAttribute('data-url');

        if (idBuscadorItemSelected >= 0) {
            jquery("#buscadorSubmit").attr("data-url", "");
            inputSearch = document.getElementById("input-search");
            inputSearch.value = textBuscadorItemSelected;
        }
        else {
            console.log("Error Selección Buscador: " + idBuscadorItemSelected);
        }
    };

    var __mostrarOpcionesPorDefecto = function () {
        var tipOpSelected,
            tipOperacionSelected,
            tipInmSelected,
            tipInmSelectedNumber,
            urlMasProvincias,
            busquedasReciente,
            busquedasRecienteLength,
            listaOpciones,
            isRecent;

        listaOpciones = [];
        tipOpSelected = jquery("#TipoOperacion option:selected").attr("data-descbuscador");
        tipOperacionSelected = jquery("#TipoOperacion option:selected").val();
        tipInmSelected = jquery("#TipInmSeleccionado option:selected").val();
        tipInmSelectedNumber = jquery("#TipInmSeleccionado option:selected").attr("data-inmueble");


        urlMasProvincias = "/" + tipOpSelected + "-" + tipInmSelected + "-" + __buscadorDTO.TextoEn + "-X/m_selinmueble.htm";

        listaOpciones.push("<ul id='search-result'>");

        listaOpciones.push("<li class='selecciona-ubicacion'><a id='lnkGeolocalizacion' href='#' title='Seleccionar mi ubicacion'>Seleccionar mi ubicacion</a></li>");
        listaOpciones.push("<li class='selecciona-provincia'><a class='selecciona-provincia' href='" +urlMasProvincias + "' title='Seleccionar una provincia'>Seleccionar una provincia</a></li>");

        __tcfapi && __tcfapi('getTCData',2, data => {
            if(data.purpose.consents[5] && data.purpose.consents[6] && data.purpose.consents[10]){
                busquedasRecienteLength = __buscadorDTO.BusquedasRecientesDistintasPoblaciones.length;
                if (busquedasRecienteLength > 3) {
                    busquedasRecienteLength = 3;
                }
        
                busquedasReciente = __buscadorDTO.BusquedasRecientesDistintasPoblaciones;
                busquedasReciente.forEach((busqueda) => {
                    if (tipOperacionSelected === busqueda.TipOp && tipInmSelectedNumber === busqueda.TipInm.toString()) {
                        isRecent = true;
                    }
                });
        
                if (isRecent) {
                    listaOpciones.push("<li class='busquedaRecienteTitle'>Más recientes</li>");
                }
        
                busquedasReciente.forEach((busqueda, i) => {
                    if (tipOperacionSelected === busqueda.TipOp && tipInmSelectedNumber === busqueda.TipInm.toString()) {
                        listaOpciones.push("<li class='busquedaReciente' data-value='" + i + "' data-text-search='" + busqueda.TextSearch + "' data-text-show='" + busqueda.TextShow + "'><a  class='busquedaReciente' href='" + busqueda.UrlNavigation + "' title='" + busqueda.TextShow + "'>" + busqueda.TextShow + "</a></li>");
                    }
                });
            }
        });

        listaOpciones.push("</ul>");
        document.getElementById('search-result-container').innerHTML = listaOpciones.join('');

        //registar el evento click en mi posición


        jquery("#lnkGeolocalizacion").click(
            function (e) {
                e.preventDefault();
                __callbackClickSeleccionarUbicacionLista();
            });

        jquery("#search-result").on("click", "li", function () {
            __callbackClickSelectElement.call(this, __buscadorDTO.BusquedasRecientesDistintasPoblaciones);
            __limpiarResultadosBuscador();
        });
    };

    var __callbackClickSeleccionarUbicacionLista = function () {
        __cargarBuscadorItemFromGeoLocation();
        __limpiarResultadosBuscadorUI.call(this);
        __mostrarIconoGeolocalziacionInput();
        var $inputGeo = jquery("#idGeoLocalizacion");
        if (!$inputGeo.is(":checked")) {
            $inputGeo.prop("checked", true);
        }
    };

    var __limpiarResultadosBuscadorUI = function () {

        var $resultadosBusqueda,
           $searchResultContainer;
        $resultadosBusqueda = jquery("#search-result");
        $resultadosBusqueda.off("click", "li");
        $searchResultContainer = jquery("#search-result-container");
        $searchResultContainer.empty();
    };

    var __limpiarResultadosBuscador = function () {
        jquery("#buscadorSubmit").attr("data-url", "");
        __limpiarResultadosBuscadorUI.call(this);

    };

    var __cargarBuscadorItemFromGeoLocation = function () {
        var buscadorItemSelected,
            inputSearch,
            __functionGeoSuccess,
            __functionGeoError;

        __functionGeoSuccess = function (position) {
            var tipOpSelected = jquery("#TipoOperacion option:selected").val();
            var tipInmSelected = jquery("#TipInmSeleccionado option:selected").val();
            
            var data = {
                tipoOperacion: tipOpSelected,
                tipoProducto: {
                    Des_Tip: tipInmSelected
                },
                lat: position.coords.latitude,
                lon: position.coords.longitude
            };

            __ShowGeoLocLoading();

            if (data.lat !== 0 && data.lon !== 0) {
                jquery.ajax({
                    type: "POST",
                    url: __buscadorDTO.UrlFindBuscadorItemByLatLon,
                    data: data,
                    cache: true,
                    dataType: "json"
                }).done(function (data) {
                    buscadorItemSelected = data;
                    if (data) {
                        jquery("#buscadorSubmit").attr("data-url", data.UrlNavigation);
                        inputSearch = document.getElementById("input-search");
                        inputSearch.value = buscadorItemSelected.TextShow;
                    }
                    else {
                        jquery(".no-localizacion").removeClass("ocultar");
                    }
                    __HideGeoLocLoading();

                }).fail(function (request, type) {
                    // jshint unused:false
                    jquery(".no-localizacion").removeClass("ocultar");
                    __HideGeoLocLoading();
                    /*var error = {
                        request: request,
                        type: type
                    };
                    throw error;*/
                });
            }
        };

        __functionGeoError = function () {
            jquery(".no-localizacion").removeClass("ocultar");
        };

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(__functionGeoSuccess, __functionGeoError);
        }
        else {
            __functionGeoError();
        }
    };
  
    var __formatNumber = function (value, n, x, s, c) {
        /*jshint bitwise: false*/
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')', num = (+value).toFixed(Math.max(0, ~~n));
        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
    };  

    var __ShowGeoLocLoading = function () {
        jquery('#loadingGeoLocalizacion').removeClass('ocultar');
        jquery('#cargando2').css('animation-iteration-count', 'infinite');
    };

    var __HideGeoLocLoading = function () {
        jquery('#loadingGeoLocalizacion').addClass('ocultar');
        jquery('#cargando2').css('animation-iteration-count', '0');
    };

    var __getTextoParametrosExtraPermitidosBuscador = function (url) {
        var textoResult = '';
        var codZonasUrl = HabUtils.GetURLParameterFromGivenURL('codZonas', url);
        var codDistritosUrl = HabUtils.GetURLParameterFromGivenURL('codDists', url);
        if (codDistritosUrl !== null && codDistritosUrl !== '') {
            textoResult = "?codDists=" + codDistritosUrl;
            if (codZonasUrl !== null && codZonasUrl !== '') {
                textoResult = textoResult + "&codZonas=" + codZonasUrl;
            }

        } else if (codZonasUrl !== null && codZonasUrl !== '') {
            textoResult = "?codZonas=" + codZonasUrl;
        }
        return textoResult;
    };
    
    Buscador.prototype.constructor = Buscador;
    Buscador.prototype.RegisterEvents = __registerEvents;

    if (typeof module !== 'undefined' && module.exports) {
        module.exports = new Buscador(BuscadorDTO);
    }
    else {
        window.Buscador = new Buscador(BuscadorDTO);
    }
}());